import {IMenuModule} from './../model/menu/Menu';
import {FileDetails} from './../model/FileDetails';
import {IActionResults} from './../model/ActionResults';
import {IActionResultsList, IActionRes} from '../model/ActionResults';
import {ITenantModuleDetails} from '../model/tenant/TenantModuleDetails';
import useHttp from '../hooks/useHttp';
import {ITenant, ITenantTemplate, ITenantInfo, ITenantUserDetails} from '../model/Tenant';
import {IConnectedSupplier} from '../model/supplier/ConnectedSupplier';

interface ISchedulesConfigRequest {
  TaskTypeID: number;
  WorkflowID: null | number;
  IsActive: boolean;
  IsRecurringSchedule: boolean;
  IsEnabled: boolean;
  StatusType: number;
}

interface IUpdateModuleReqBody {
  ParentModuleID: number;
  IsModuleSetup: boolean;
  configureSchedules?: boolean;
  MarketPlaceURL?: boolean;
  SchedulesConfig?: ISchedulesConfigRequest[];
}

interface ITenantSetupDetails {
  ARContactEmailAddress: string;
  IsCapricornPreferredSupplier: boolean;
  IsShowBusinessLogoOnPOSReceipt: boolean;
  POSReceiptFooterText: string;
  Tenant: ITenant;
}

const useTenantAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  const getActiveModules = (): Promise<ITenantModuleDetails[]> => {
    return GET('tenant/getactivemodules').then((data: IActionResultsList<ITenantModuleDetails>) => data.Items);
  };

  const getCompanyDetails = (): Promise<ITenant | undefined> => {
    return GET('tenant/GetSetupDetails').then((data: IActionResults<ITenantSetupDetails>) => {
      const t = data.Value ? data.Value.Tenant : undefined;

      if (t && data.Value) {
        // Copy config at tenant level
        t.ARContactEmailAddress = data.Value.ARContactEmailAddress;
        t.IsCapricornPreferredSupplier = data.Value.IsCapricornPreferredSupplier;
      }

      return t;
    });
  };

  const saveTenantDetails = (tenant: Partial<ITenant>, arContactEmailAddress?: string): Promise<IActionRes> => {
    delete tenant?.IsCapricornPreferredSupplier;

    const data = {
      Tenant: tenant,
      ARContactEmailAddress: arContactEmailAddress,
    };

    if (!arContactEmailAddress) {
      delete data.ARContactEmailAddress;
    }

    if (tenant && !tenant.ARContactEmailAddress) {
      delete data.Tenant.ARContactEmailAddress;
    }

    return POST('tenant/save', data).then((data: IActionRes) => data);
  };

  const saveLogo = (data: any, header: {[key: string]: string} | undefined = undefined): Promise<FileDetails[]> => {
    return POST('tenant/savelogo', data, true, false, header);
  };

  const getTemplates = (): Promise<ITenantTemplate[]> => {
    return GET('tenant/templates').then((data: IActionResults<any>) => (data.Value ? data.Value : undefined));
  };

  const getTenantInfo = (): Promise<ITenantInfo> => {
    return GET('tenant/info').then((data: IActionResults<any>) => (data.Value ? data.Value : undefined));
  };

  const saveTenantModule = (moduleid: number, isModuleSetup: boolean) => {
    const data = {
      Value: {
        IsModuleSetup: isModuleSetup,
        ModuleID: moduleid,
      },
    };
    return POST('tenantmodule', data).then((data: IActionRes) => data);
  };

  const updateModule = (UpdateModuleBody: IUpdateModuleReqBody): Promise<IMenuModule[]> => {
    return POST(`Spenda/Tenant/Modules/Menu/Module`, UpdateModuleBody).then(data => data.value);
  };

  const setupCompleteUpdate = (tenantDetails: ITenantInfo): Promise<IActionRes> => {
    const data = {
      IsSetupComplete: tenantDetails,
    };
    return POST('tenant/', data).then((data: IActionRes) => data);
  };

  const saveTenantTemplate = (TemplateID: number) => {
    const data = {
      Value: {
        TemplateID,
      },
    };
    return POST('tenant/template', data).then((data: IActionRes) => data);
  };

  const matchAndLink = (statementInvitationId: string): Promise<IActionResults<IConnectedSupplier>> => {
    return POST(`tenant/matchAndLink/${statementInvitationId}`, {}).then(
      (data: IActionResults<IConnectedSupplier>) => data,
    );
  };

  const getTenantUserDetails = (): Promise<ITenantUserDetails> => {
    return GET('tenant/GetTenantUserDetails').then((data: any) => (data ? data : undefined));
  };

  const airplusVirtualCreditRequest = (): Promise<any> => {
    return POST('Spenda/Tenant/Modules/AirplusVirtualCredit/ApplicationRequest', undefined);
  };

  const acceptFeeTerm = (payload: {ModuleID: number | null; SubscriptionText: string}): Promise<IActionRes> => {
    return POST(`Spenda/Tenant/Subscriptions/Subscribe`, payload);
  };

  const acceptTermsAndConditions = (payload: {TermsAndConditionID: number}): Promise<IActionRes> => {
    return POST(`Spenda/Tenant/TermsAndConditions/Accept`, payload);
  };

  return {
    isLoading,
    getActiveModules,
    getCompanyDetails,
    getTemplates,
    getTenantInfo,
    matchAndLink,
    saveLogo,
    saveTenantDetails,
    saveTenantModule,
    saveTenantTemplate,
    setupCompleteUpdate,
    getTenantUserDetails,
    updateModule,
    airplusVirtualCreditRequest,
    acceptFeeTerm,
    acceptTermsAndConditions,
  };
};

export default useTenantAPI;
