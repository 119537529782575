import {Dispatch, SetStateAction} from 'react';
import {ILogo, ITenant} from '../../model/Tenant';
import {IUser} from '../../model/user/User';
import {css} from 'glamor';
import {UserTenantDropDown} from '../tenant/UserTenant';
import {NotificationsButton} from '../layout/Header';
import {Profile} from '../../assets/svg/Profile';
import {Settings} from '../../assets/svg/Settings';
import {NameInitials, capitalizeInitials} from '../../utils/formatter';
import {Typography} from 'spenda-ui-react';
import {Box} from '@material-ui/core';
import SpendaLogo from '../../assets/svg/SpendaNewLogo.svg';
import CloseIcon from '../../assets/svg/cross_small.svg';
import React from 'react';
import {useHistory} from 'react-router-dom';
import APLogoIcon from '../../assets/svg/accounts-payable/APLogoIcon';
import IconCapricornLogo from '../../assets/svg/IconCapricornLogo';
import IconcapricornFull from '../../assets/svg/IconCapricornFull';
import clsx from 'clsx';
import {VisibleContent} from '../ui/VisibleContent';
import useTheme from '../../context/theme-context/useTheme';
import CapricornLogo from '../../assets/png/CapricornLogo.png';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {IconStatements} from '../../assets/svg/menu/Statements';

export interface IHeaderProps {
  supplierIcon: ILogo | undefined;
  handleClose?: () => void;
  user: IUser;
  tenant: ITenant;
  setUserAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  setSettingsAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  setNotificationsAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
  isDialog?: boolean;
  isAPOnboardingV1Header?: boolean;
  isPsblOnboarding?: boolean;
}

export const Header = (props: IHeaderProps) => {
  const {
    supplierIcon,
    handleClose,
    user,
    tenant,
    setUserAnchorEl,
    setSettingsAnchorEl,
    setNotificationsAnchorEl,
    isDialog,
    isAPOnboardingV1Header,
    isPsblOnboarding,
  } = props;
  const history = useHistory();
  const userDetail = css({
    '& span': {
      color: 'hsl(var(--primary))',
    },
  });

  const {logo: Logo} = useTheme();

  const {lmiCommercialRelease92671} = useFeatureFlags().supplierDriven();

  const close = css({
    right: '2.5rem',
    top: '1.25rem',
    '@media(max-width: 576px)': {
      top: '-.625rem',
      right: '1.25rem',
    },
    ' & svg': {
      color: 'hsl(var(--primary))',
      fontSize: '1.25rem',
    },
  });

  return (
    <>
      <div
        className={clsx(
          'flex justify-between',
          isAPOnboardingV1Header ? 'bg-primary-header p-2.5' : 'p-4',
          isPsblOnboarding && (lmiCommercialRelease92671 ? 'bg-primary-header' : 'bg-white'),
        )}
      >
        <div className={clsx('flex', isPsblOnboarding && 'items-center')}>
          {isPsblOnboarding && !lmiCommercialRelease92671 ? (
            <IconCapricornLogo />
          ) : (
            <button
              className={clsx(
                'focus:outline-none',
                isAPOnboardingV1Header && '!mr-5 h-16 w-16 cursor-pointer rounded-md bg-white p-1',
              )}
              data-autoid="btnMySpenda"
              style={{margin: isAPOnboardingV1Header ? '0px' : '-4px 0px 0px 3px'}}
              onClick={() => (isAPOnboardingV1Header && !lmiCommercialRelease92671 ? history.push('/menu') : null)}
            >
              {Logo && !lmiCommercialRelease92671 ? (
                <img src={CapricornLogo} alt="Capricorn" className="w-14" />
              ) : (
                <img
                  alt={'My Spenda'}
                  src={SpendaLogo}
                  className={isAPOnboardingV1Header ? 'mx-auto h-[44px] w-[29px]' : ''}
                />
              )}
            </button>
          )}
          {isAPOnboardingV1Header && (
            <div className="flex items-center justify-center">
              {isPsblOnboarding ? (
                lmiCommercialRelease92671 && <IconStatements />
              ) : (
                <APLogoIcon width={'40px'} height={'40px'} />
              )}
              <div>
                <Typography
                  variant="h2"
                  className={clsx(
                    'ml-5 leading-[18px] text-black-800',
                    {'text-2xl font-light': isPsblOnboarding},
                    {'font-normal': !isPsblOnboarding},
                  )}
                >
                  {!isPsblOnboarding
                    ? 'Accounts Payable Management'
                    : lmiCommercialRelease92671
                      ? 'My Capricorn Statement'
                      : ''}
                </Typography>
                {isPsblOnboarding && lmiCommercialRelease92671 && (
                  <Typography variant="h2" className="ml-5 text-sm font-light">
                    by Spenda
                  </Typography>
                )}
              </div>
            </div>
          )}
        </div>
        {isPsblOnboarding && !lmiCommercialRelease92671 && <IconcapricornFull className="!h-[64px]" />}
        <Box className={`flex ${isAPOnboardingV1Header ? 'items-center' : 'items-start'}`}>
          <Box className={`mr-4 text-right text-black-800 ${userDetail} text-base`}>
            <UserTenantDropDown
              userName={capitalizeInitials(user.FirstName) + ' ' + capitalizeInitials(user.LastName)}
              tenantName={capitalizeInitials(tenant?.Name || '')}
            />
          </Box>
          <Box
            className="mr-3 cursor-pointer"
            data-autoid={`btnProfile`}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              if (setUserAnchorEl) {
                setUserAnchorEl(event.currentTarget);
              }
            }}
          >
            <Profile />
          </Box>

          <VisibleContent keyPath="settingMenu">
            <Box
              data-autoid={`btnSettings`}
              className="cursor-pointer"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (setSettingsAnchorEl) {
                  setSettingsAnchorEl(event.currentTarget);
                }
              }}
            >
              <Settings />
            </Box>
          </VisibleContent>

          <VisibleContent keyPath="notifications">
            <NotificationsButton
              dataAutoid={`btnNotifications`}
              className="ml-3 cursor-pointer"
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                if (setNotificationsAnchorEl) {
                  setNotificationsAnchorEl(event.currentTarget);
                }
              }}
            />
          </VisibleContent>
        </Box>
      </div>
      {!isAPOnboardingV1Header && !isPsblOnboarding && (
        <div className="smMax:mt-2 relative mb-8 flex flex-col items-center justify-center sm:-mt-8 ">
          {supplierIcon?.URI ? (
            <div className="mb-2 rounded-md">
              <img
                {...css({
                  width: '94px',
                  height: '94px',
                  objectFit: 'fill',
                })}
                alt=""
                className="rounded-full"
                src={supplierIcon?.URI}
                data-autoid="imgSupplierIcon"
              />
            </div>
          ) : (
            <div className="mb-2">
              <Typography className="flex h-24 w-24 items-center justify-center rounded-full bg-success p-2 text-[60px] text-white">
                {NameInitials(user.FirstName + ' ' + user.LastName)}
              </Typography>
            </div>
          )}
          <Typography data-autoid="lblWelcome" variant="h1">
            Welcome, {capitalizeInitials(user.FirstName)}
          </Typography>
          {isDialog && (
            <div
              className={`${close} text-xxl absolute right-10 flex cursor-pointer font-poppins font-light text-black-800`}
              onClick={handleClose}
            >
              <img alt={'close'} src={CloseIcon} /> <span className="smMax:hidden ml-2">Close</span>
            </div>
          )}
        </div>
      )}
    </>
  );
};
