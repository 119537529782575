import React from 'react';
import {Radio, Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import { AuthorisePayment } from '../../assets/svg/accounts-payable/AuthorisePayment';

interface IInvoiceGenerateDialogProps {
  handleCancel: () => void;
}
export const InvoiceGenerateDialog = (props: IInvoiceGenerateDialogProps) => {
  return (
      <AlertDialogSlideV2
        headingClassess="!border-b-0 "
        dialogActionsAlignment="justify-between"
        data-autoid={'dlgQuoteSent'}
        dialogClassess="!w-[320px] !min-w-[320px]"
        actions={[
          {
            label: 'cancel',
            loading: false,
            action: props.handleCancel,
            variant: 'outlined',
          },
          {
            label: 'Send Invoice',
          },
        ]}
      >
        <div className="flex flex-col gap-2.5 mt-4">
          <div className="relative mt-2.5 flex w-full justify-center">
            <AuthorisePayment className='w-[88px] h-[88px]' />
          </div>

          <div className="mb-4 mt-8 flex flex-col items-center justify-center gap-4">
            <Typography variant="h2" className="mb-6 text-primary">
              Invoice generated
            </Typography>
            <div className="flex w-[234px] flex-col justify-between gap-6 text-center font-normal text-black-800">
              <Typography variant="small">You have successfully generated the invoice.</Typography>
              <Typography variant="small">Would you like to send this invoice via Pay invoice by link?</Typography>
            </div>
            <div className="flex items-center justify-center">
              <Radio color="primary" name="type" />
              <Typography variant="h1" className={`text-base font-semibold text-black`}>
                Send as Pay invoice by link
              </Typography>
            </div>

            <div className="flex w-[268px] items-center justify-between mt-4 rounded-md bg-primary/10 p-2">
              <Typography variant="small" className="text-black-800 ">
                Total amount:
              </Typography>
              <Typography variant="paragraph" className="font-semibold text-black-900">
                $3231.00
              </Typography>
            </div>
          </div>
        </div>
      </AlertDialogSlideV2>
  );
};
