import React, {useState} from 'react';

import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {Button, Typography} from 'spenda-ui-react';
import {RoundedCheckbox} from '../../../components/inputs/RoundedCheckbox';
import {CreateServiceJobModal} from '../../../components/dialog/CreateServiceJobModal';
import {ServiceContextProvider} from '../../../context/serviceManagement/ServiceContextProvider';
import {InvoiceGenerateDialog} from '../../../components/dialog/InvoiceGenerateDialog';

const ServiceUninvoiceJobList = () => {
  const [openBookingDialog, setOpenBookingDialog] = useState<boolean>(false);
  const [isOpenDialog, setIsOpenDialog] = useState<boolean>(false);

  const columns = [
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (_index: number) => {
        return <RoundedCheckbox onClick={() => setIsOpenDialog(true)} />;
      },
    },
    {
      title: 'Completion date',
      key: 'completionDate',
      width: '8%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtCompleteDate-${index}`}>
          23 may 2024
        </Typography>
      ),
    },
    {
      title: 'Customer name',
      key: 'CustomerName',
      width: '8%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" className="cursor-pointer text-primary underline" data-autoId={`ref-${index}`}>
          <span
            onClick={() => {
              setOpenBookingDialog(true);
            }}
            data-autoid={`lnk-${index}`}
          >
            Barry Holden
          </span>
        </Typography>
      ),
    },
    {
      title: 'Primary tech',
      key: 'PrimaryTech',
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtPrimaryTech-${index}`}>
          Ben Harper
        </Typography>
      ),
    },
    {
      title: 'Asset details',
      key: 'AssetDetails',
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" className="max-w-[15rem] truncate" data-autoid={`txtAssetDetails-${index}`}>
          211 South Terrace, South Fremantle WA 6162
        </Typography>
      ),
    },
    {
      title: 'Customer email',
      key: 'customerEmail',
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtCustomerEmail-${index}`}>
          barry@theboozery.com
        </Typography>
      ),
    },
    {
      title: 'Phone no.',
      key: 'phoneNo.',
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtPhoneNumber-${index}`}>
          0495 567 333
        </Typography>
      ),
    },
    {
      title: 'No. of lines',
      key: 'NoOfLines',
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtNoOfLines-${index}`}>
          3
        </Typography>
      ),
    },
    {
      title: 'Total inc',
      key: 'TotalInc',
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtTotalInc-${index}`}>
          $22.50
        </Typography>
      ),
    },
    {
      title: 'Total line qty',
      key: 'TotalLineQty',
      width: '6%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (index: number) => (
        <Typography variant="small" data-autoid={`txtTotalLineQty-${index}`}>
          3
        </Typography>
      ),
    },
  ];

  return (
    <div className="h-full w-full">
      <div className="my-2 h-1 w-full border-b border-b-[#ECECEC]" />
      <div className="ml-4 flex items-center justify-start gap-2.5 font-poppins">
        <RoundedCheckbox label="Select all" onClick={()=>{setIsOpenDialog(true)}} />
        <Button onClick={() => {}} variant="outlined" disabled>
          Generate Invoice
        </Button>
      </div>
      <div className={`h-[calc(100vh-250px)]`}>
        <ARTable
          isHighlightRowOnHover
          columns={columns}
          scope="AR"
          rows={Array(4).fill(2)}
          isLoading={false}
          tableClass="!mx-0 mt-2.5"
        />
      </div>
      {openBookingDialog && (
        <ServiceContextProvider>
          <CreateServiceJobModal
            open={openBookingDialog}
            handleClose={() => {
              setOpenBookingDialog(false);
            }}
          />
        </ServiceContextProvider>
      )}
      {isOpenDialog && (
        <InvoiceGenerateDialog
          handleCancel={() => {
            setIsOpenDialog(false);
          }}
        />
      )}
    </div>
  );
};

export default ServiceUninvoiceJobList;
