import React, {useEffect, useState} from 'react';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import {Layout} from '../../../components/layout/Layout';
import {useLoadCustomersList} from '../../../services/useLoadCustomerList';
import ServiceUninvoiceJobList from './ServiceUninvoiceJobList';
import ServiceUninvoiceHeader from './ServiceUninvoiceHeader';

export const ServiceUninvoice = () => {
  const [searchString, setSearchString] = useState<string>();
  const [columnOptions, setColumnOptions] = useState<string[]>([
    'Completion date',
    'Company name',
    'Primary tech',
    'Asset details',
    'Customer email',
    'Phone no.',
    'No. of lines',
    'Total inc',
    'Total line qty'
  ]);

  const {
    loading: isLoading,
    searchFilter,
    setSearchFilter
  } = useLoadCustomersList();

  useEffect(() => {
    if (typeof searchString != 'undefined') {
      setSearchFilter({
        ...searchFilter,
        SearchString: searchString,
      });
    }
  }, [setSearchString, searchString]);

  const _serviceHistory = (
    <div className="flex h-[calc(100vh-120px)] flex-col items-start justify-between rounded bg-white p-2">
      <ServiceUninvoiceHeader
        columnOptions={columnOptions}
        setColumnOptions={setColumnOptions}
        setSearchString={setSearchString}
      />
      <ServiceUninvoiceJobList />
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-[rgba(211,229,239,0.5)]`}>
      <LoadingIndicator isLoading={isLoading} size="md" />
      <Layout leftPanel={_serviceHistory} splitWidthType={4} />
    </div>
  );
};
