import React from 'react';
import {IconButton, SearchInput, Typography} from 'spenda-ui-react';
import IconStatusFilter from '../../../../assets/svg/IconStatusFilter';
import {IconSort} from '../../../../assets/svg';
import {CustomerListTable} from './CustomerListTable';
import {AlertDialogSlideV2} from '../../../../components/dialog/AlertDialogSlideV2';

interface ISendCatalogueDialog {
  handleCancel: () => void;
}
export const SendCatalogueDialog: React.FC<ISendCatalogueDialog> = ({handleCancel}) => {
  return (
    <>
      <AlertDialogSlideV2
        headingClassess="border-b border-[#EAEAEA] flex items-center justify-between"
        dialogActionsAlignment="justify-between"
        dialogBodyClassess="flex max-h-[750px] flex-col justify-center gap-3 overflow-y-scroll"
        dialogClassess="!min-w-[948px]"
        actions={[
          {
            label: 'Cancel',
            variant: 'outlined',
            action: handleCancel,
          },
          {
            label: 'Send',
            action: () => {},
          },
        ]}
        title={' '}
        headerChildren={
          <div className="flex w-full items-center justify-between">
            <div className="flex-1"></div> {/* Empty div to take up space on the left */}
            <div className="flex-1 text-center">
              <h2 className="text-2xl font-light">Send Catalogue</h2>
            </div>
            <div className="flex flex-1 justify-end gap-3">
              <span className="text-sm" data-autoid="btnFilterBtn">
                <IconButton variant="outlined" name="Sort">
                  <IconSort />
                </IconButton>
              </span>
              <span className="text-sm" data-autoid="btnFilterBtn">
                <IconButton variant="outlined" name="statusFilter">
                  <IconStatusFilter />
                </IconButton>
              </span>
            </div>
          </div>
        }
      >
        <div className="flex flex-col items-center justify-center gap-2.5">
          <Typography variant="paragraph" className="mx-12 py-6 text-center text-black">
            A few lines about the invite process and once the customer accepts then they are able to use the Catalogue.
          </Typography>
          <div className="mb-10 flex w-[350px] items-center justify-center">
            <SearchInput
              expanded
              reverse={true}
              clearIcon
              iconButtonProps={{
                name: 'Search',
              }}
              data-autoid="txtSearchInput"
              autoFocus
              placeholder="Search by name or phone number"
            />
          </div>
        </div>
        <div className="mx-10">
          <CustomerListTable />
        </div>
      </AlertDialogSlideV2>
    </>
  );
};
