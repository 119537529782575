import useHttp from '../hooks/useHttp';

import {
  IClaimRequestDetailsResponse,
  IClaimRequestListResponse,
  IUpdateClaimStatusResponse,
  IVendorListResponse,
  IClaimsReasonResponse,
  IClaimNotes,
  IClaimLineNoteUpdateRequest,
} from '../model/claims/ClaimsAndReturns';
import {ClaimAndReturnRowAction, ClaimsAndReturnsStatus} from '../model/constants/Constants';

const useClaimsAndReturnsAPI = () => {
  const {GET, POST, isLoading} = useHttp();

  // New Endpoints
  const getClaimRequestListSupplier = (query?: {
    status?: string;
    accountCustomerID?: number;
    searchField?: string;
    searchString?: string;
  }): Promise<IClaimRequestListResponse> => {
    return GET(`Spenda/ClaimsAndReturns/Returns/Supplier/ClaimRequest`, query);
  };

  const getClaimRequestListBuyer = (query: {
    linkedSupplierId: number;
    status?: string;
    searchField?: string;
    searchString?: string;
  }): Promise<IClaimRequestListResponse> => {
    return GET(`Spenda/ClaimsAndReturns/Returns/Buyer/ClaimRequest/`, query);
  };

  const getClaimRequestDetails = (claimId: number): Promise<IClaimRequestDetailsResponse> => {
    return GET(`Spenda/ClaimsAndReturns/Returns/ClaimRequest/${claimId}`);
  };

  const getClaimRquestDetailBuyer = (
    linkedSupplierId: number,
    claimRequestId: number,
  ): Promise<IClaimRequestDetailsResponse> => {
    return GET(`Spenda/ClaimsAndReturns/Returns/Buyer/ClaimRequest/${linkedSupplierId}/${claimRequestId}`);
  };

  const getClaimRquestDetailSupplier = (
    accountCustomerId: number,
    claimRequestId: number,
  ): Promise<IClaimRequestDetailsResponse> => {
    return GET(`Spenda/ClaimsAndReturns/Returns/Supplier/ClaimRequest/${accountCustomerId}/${claimRequestId}`);
  };

  const createClaimRequest = (payload: ICreateClaimRequestPayload): Promise<IClaimRequestDetailsResponse> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/ClaimRequest/Create`, payload);
  };

  const updateClaimRequest = (
    claimRequestId: number,
    payload: IUpdateClaimRequestPayload,
  ): Promise<IClaimRequestDetailsResponse> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/ClaimRequest/${claimRequestId}/Update`, payload);
  };

  const updateClaimAndReturnStatus = (
    claimRequestId: number,
    payload: IUpdateClaimStatusPayload,
  ): Promise<IUpdateClaimStatusResponse> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/ClaimRequest/${claimRequestId}/UpdateStatus`, payload);
  };

  const getVendorList = (query?: {
    linkedSupplierID?: number;
    searchString?: string;
  }): Promise<IVendorListResponse[]> => {
    return GET(`Spenda/Payments/AccountsReceivable/Vendors`, query);
  };

  const getClaimReasonSupplier = (): Promise<IClaimsReasonResponse[]> => {
    return GET(`/Spenda/ClaimsAndReturns/Common/Supplier/ClaimReasons`);
  };

  const getClaimReasonBuyer = (linkedSupplierID: number): Promise<IClaimsReasonResponse[]> => {
    return GET(`/Spenda/ClaimsAndReturns/Common/Buyer/ClaimsReasons/${linkedSupplierID}`);
  };

  const disputeClaimRequest = (payload: IDisputeRejectionPayload): Promise<IClaimRequestDetailsResponse> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/ClaimRequest/Dispute`, payload);
  };

  const createEarlyCreditNote = (payload: ICreateEarlyCreditNotePayload): Promise<IClaimRequestDetailsResponse> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/EarlyShortPaymentTransactions/Create`, payload);
  };

  const saveBuyerInternalNote = (
    comment: string,
    claimRequestID: number,
    linkedSupplierID: number,
  ): Promise<IClaimNotes> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/Buyer/ClaimRequest/${linkedSupplierID}/${claimRequestID}/Comment`, {
      comment,
    });
  };

  const saveSupplierInternalNote = (comment: string, claimRequestID: number): Promise<IClaimNotes> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/Supplier/ClaimRequest/${claimRequestID}/Comment`, {comment});
  };

  const updateBuyerInternalNote = (payload: IClaimNotes, linkedSupplierID: number): Promise<IClaimNotes> => {
    return POST(
      `Spenda/ClaimsAndReturns/Returns/Buyer/ClaimRequest/${linkedSupplierID}/${payload.claimRequestID}/Comment`,
      payload,
    );
  };

  const updateSupplierInternalNote = (payload: IClaimNotes): Promise<IClaimNotes> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/Supplier/ClaimRequest/${payload.claimRequestID}/Comment`, payload);
  };

  const updateSupplierExternalNote = (payload: IClaimLineNoteUpdateRequest): Promise<IClaimRequestDetailsResponse> => {
    return POST(`Spenda/ClaimsAndReturns/Returns/Supplier/ClaimRequest/${payload?.claimRequestID}/LineNote`, payload);
  };

  const updateBuyerExternalNote = (
    payload: IClaimLineNoteUpdateRequest,
    linkedSupplierID: number,
  ): Promise<IClaimRequestDetailsResponse> => {
    return POST(
      `Spenda/ClaimsAndReturns/Returns/Buyer/ClaimRequest/${linkedSupplierID}/${payload.claimRequestID}/LineNote`,
      payload,
    );
  };

  const getSupplierNotesList = (claimRequestID: number): Promise<IClaimNotes[]> => {
    return GET(`Spenda/ClaimsAndReturns/Returns/Supplier/ClaimRequest/${claimRequestID}/Comment`);
  };

  const getBuyerNotesList = (claimRequestID: number, linkedSupplierID: number): Promise<IClaimNotes[]> => {
    return GET(`Spenda/ClaimsAndReturns/Returns/Buyer/ClaimRequest/${linkedSupplierID}/${claimRequestID}/Comment`);
  };

  return {
    getClaimRquestDetailSupplier,
    getClaimRquestDetailBuyer,
    getClaimRequestListSupplier,
    getClaimRequestListBuyer,
    getClaimRequestDetails,
    createClaimRequest,
    updateClaimRequest,
    updateClaimAndReturnStatus,
    getVendorList,
    getClaimReasonSupplier,
    getClaimReasonBuyer,
    disputeClaimRequest,
    createEarlyCreditNote,
    saveBuyerInternalNote,
    saveSupplierInternalNote,
    updateSupplierInternalNote,
    updateBuyerInternalNote,
    updateBuyerExternalNote,
    updateSupplierExternalNote,
    getSupplierNotesList,
    getBuyerNotesList,
    isLoading,
  };
};

export default useClaimsAndReturnsAPI;

interface ICreateAndUpdateCommonPayload {
  invoiceID?: number;
  accountCustomerID?: number;
  linkedSupplierID?: number;
  vendorID?: number;
}
export interface ICreateClaimRequestLinePayload {
  invoiceLineID?: number | null;
  description?: string;
  amountEx?: number;
  isTaxExempt?: boolean;
  quantity: number;
  claimReason: string;
  returnType: string;
  action: ClaimAndReturnRowAction;
  notes?: string;
  inventoryID?: null | number;
}

interface IUpdateClaimRequestLinePaylaod extends ICreateClaimRequestLinePayload {
  claimRequestLineID?: number;
}

export interface ICreateClaimRequestPayload extends ICreateAndUpdateCommonPayload {
  comment?: string | null;
  defaultClaimReason: string;
  defaultReturnType: string;
  invoiceRefNumber?: string;
  invoiceDate?: string;
  lines: ICreateClaimRequestLinePayload[];
}

export interface IUpdateClaimRequestPayload extends ICreateAndUpdateCommonPayload {
  comment?: string | null;
  defaultClaimReason?: string;
  defaultReturnType?: string;
  lines: IUpdateClaimRequestLinePaylaod[];
  isPendingCustomerAction?: boolean;
}

export interface IUpdateClaimStatusPayload {
  status: ClaimsAndReturnsStatus;
  comment?: string;
  linkedSupplierID?: number;
  accountCustomerID?: number;
  VendorCreditNoteDate?: string;
  SupplierReferenceNumber?: string;
}

export interface IUploadClaimsAttachmentJsonRequest {
  ClaimRequestID: number;
  ClaimRequestLineID: number; // Optional
  AccountCustomerID?: number;
  LinkedSupplierID?: number;
  UserID?: number; // Should Be deleted
  MerchantTenantID?: number; // Should Be deleted
  BuyerTenantID?: number; // Should Be deleted
  Attachments: {
    Caption: string;
    Note: string;
    Action: number;
  }[];
}
export interface IDisputeRejectionPayload {
  claimRequestID: number;
  accountCustomerID?: number;
  linkedSupplierID?: number;
}

export interface ICreateEarlyCreditNotePayload {
  claimRequestID: number;
  accountCustomerID?: number;
  linkedSupplierID?: number;
}
