import React from 'react';
import { Typography} from 'spenda-ui-react';
import {RoundedCheckbox} from '../../../../components/inputs/RoundedCheckbox';
import {ARTable} from '../../../../components/AccountsReceivable/ARTable';

export const ManageSubscribersTable = () => {
  const columns = [
    {
      title: '',
      key: '',
      width: '1%',
      align: 'left',
      rowClassName: 'p-2.5',
      headerRenderer: () => {
        return <RoundedCheckbox />;
      },
      rowRenderer: (_index: number) => {
        return <RoundedCheckbox />;
      },
    },
    {
      title: 'Name',
      key: 'name',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return (
          <Typography className="font-medium text-primary" data-autoid={`txtName-${index}`}>
            Alexander Sanchez
          </Typography>
        );
      },
    },
    {
      title: 'Email',
      key: 'email',
      width: '13%',
      align: 'left',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtEmail-${index}`}>alexander.sanchez@carpetcourt.com</Typography>;
      },
    },
    {
      title: 'Contact name',
      key: 'contactName',
      width: '13%',
      align: 'center',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtContactName-${index}`}>Liam Johnson</Typography>;
      },
    },
    {
      title: 'Phone number',
      key: 'phoneNumber',
      width: '13%',
      align: 'center',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtPhoneNumber-${index}`}>+61 2 1234 5783</Typography>;
      },
    },
    {
      title: 'Date',
      key: 'date',
      width: '13%',
      align: 'right',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtDate-${index}`}>Sent less then a minute ago</Typography>;
      },
    },
    {
      title: 'Status',
      key: 'status',
      width: '13%',
      align: 'center',
      rowClassName: 'p-2.5',
      rowRenderer: (index: number) => {
        return <Typography data-autoid={`txtStatus-${index}`}>-</Typography>;
      },
    },
  ];

  return (
    <>    
      <ARTable isLoading={false} rows={Array(4).fill(2)} columns={columns} tableClass="!-mx-2.5 !w-[calc(100%+20px)]" />
    </>
  );
};
