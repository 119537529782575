import clsx from 'clsx';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {Button, SearchInput, Typography} from 'spenda-ui-react';
import GoBackIcon from '../../../assets/svg/GoBackIcon';
import {DatTypes, PostingTypeOptions} from '../../../model/constants/Constants';
import {
  IDebitNoteSummmaryValue,
  IPurchaseInvoiceSummmaryValue,
  IStagedTransactions,
} from '../../../model/requires-attention/StagedTransactions';
import {getComparedStagedData} from '../../../screens/AccountsReceivable/requires-attention/RequiresAttention';
import {useStagedTransactionsAPI} from '../../../services/useStagedTransactionsAPI';
import {PriceFormat, roundTo} from '../../../utils/formatter';
import ARTooltip from '../../data-display/ARTootip';
import {ARSelectToggler} from '../ARSelectToggler';
import {ARTable} from '../ARTable';
import {InvoiceFromAdapterTemplate} from '../templates/InvoiceFromAdapterTemplate';
import {WarningDialog} from './WarningDialog';
import {SelectMatchAndLinkMethod} from './SelectMatchAndLinkMethod';
import {IPrefixSuffix, useAPConfigs} from '../../../services/useAPConfigs';
import {CloseCross} from '../../../assets/svg/CloseCross';

interface ISelectedRow {
  invoiceTotal: number;
  refNo: string;
  linkedDocumentID: number;
}

export interface ILoading {
  dataLoading: boolean;
  postAsNewLoading: boolean;
  matchAndLinkLoading: boolean;
}

interface IInvoiceFromFinancialAdaptor {
  selectedStagedTransaction: IStagedTransactions;
  summaryData: (IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue)[];
  isMatchedDocument: boolean;
  totalCapricornAmount: number | undefined;
  financialAdaptorDisplayName: string | undefined;
  isLoading: ILoading;
  handleDone: () => void;
  setLoadingState: (key: string, value: boolean) => void;
  PSBLSTAGING?: boolean;
}

export const InvoiceFromFinancialAdaptorDialog = (props: IInvoiceFromFinancialAdaptor) => {
  // Props
  const {
    isMatchedDocument,
    selectedStagedTransaction,
    summaryData,
    totalCapricornAmount,
    financialAdaptorDisplayName,
    isLoading,
    PSBLSTAGING,
    handleDone,
    setLoadingState,
  } = props;

  // State
  const [previewDocumentId, setPreviewDocumentId] = useState<number>();
  const [searchValue, setSearchValue] = useState('');
  const [isShowAmountNotMatchDialog, setIsShowAmountNotMatchDialog] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<ISelectedRow[]>([]);
  const [isShowSelectPostingMethodDialog, setIsShowSelectPostingMethodDialog] = useState<boolean>(false);
  const [isShowTooltipAlert, setIsShowTooltipAlert] = useState(true);
  const [selectedPostingType, setSelectedPostingType] = useState<PostingTypeOptions | undefined>();
  const [roundingConfig, setRoundingConfig] = useState<IPrefixSuffix>();

  // APIs
  const {matchAndLinkTransaction} = useStagedTransactionsAPI();
  const {getPrefixSuffix} = useAPConfigs();

  // Constants
  const totalSelectedRecordsAmount = selectedRows.reduce((total, row) => {
    const amount = row?.invoiceTotal;
    return total + amount;
  }, 0);
  const filteredRows = summaryData.filter(row => {
    const refNumber = row.refNumber?.toLowerCase() ?? '';
    const totalIncRounded = roundTo(row.totalInc, 2, true)?.toString().toLowerCase() ?? '';
    return refNumber.includes(searchValue.toLowerCase()) || totalIncRounded.includes(searchValue.toLowerCase());
  });
  const {datTypeID} = getComparedStagedData(selectedStagedTransaction);
  const adjustmentAmount = Math.round(((totalCapricornAmount || 0) - totalSelectedRecordsAmount) * 100) / 100;
  const selectedDocuments = selectedRows?.map(transaction => transaction?.linkedDocumentID);
  const isPurchaseInvoice = datTypeID === DatTypes.StagedPurchaseInvoice;
  const formattedAdjustmentAmount = PriceFormat(Math.abs(adjustmentAmount));

  useEffect(() => {
    if (isMatchedDocument && selectedStagedTransaction) {
      const {totalInc, refNumber, matchedDocumentID} = getComparedStagedData(selectedStagedTransaction);
      handleSelect({
        invoiceTotal: totalInc as number,
        refNo: refNumber as string,
        linkedDocumentID: matchedDocumentID as number,
      });
    }
  }, [isMatchedDocument, selectedStagedTransaction]);

  useEffect(() => {
    const loadPrefixSuffix = async () => {
      const res = await getPrefixSuffix();
      setRoundingConfig(res);
    };

    loadPrefixSuffix();
  }, []);

  const getLinkedDocumentID = (datTypeID: number, rowData: IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue) => {
    if (datTypeID === DatTypes.StagedPurchaseInvoice) {
      return (rowData as IPurchaseInvoiceSummmaryValue)?.purchaseInvoiceID;
    } else {
      return (rowData as IDebitNoteSummmaryValue)?.debitNoteID;
    }
  };

  const handleSelect = (filteredRows: ISelectedRow) => {
    setSelectedRows(prevSelectedRows => {
      const isSelected = prevSelectedRows.some(
        selectedRow => selectedRow?.linkedDocumentID === filteredRows.linkedDocumentID,
      );
      if (isSelected) {
        return prevSelectedRows.filter(selectedRow => selectedRow.linkedDocumentID !== filteredRows.linkedDocumentID);
      } else {
        return [
          ...prevSelectedRows,
          {
            refNo: filteredRows.refNo,
            invoiceTotal: filteredRows.invoiceTotal,
            linkedDocumentID: filteredRows.linkedDocumentID,
          },
        ];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedRows(prevSelectedRows => {
      const allSelected = filteredRows?.every(row => {
        return prevSelectedRows.some(
          selectedRow => selectedRow.linkedDocumentID === getLinkedDocumentID(datTypeID, row),
        );
      });
      if (allSelected) {
        return prevSelectedRows.filter(
          selectedRow =>
            !filteredRows?.some(row => {
              return selectedRow?.linkedDocumentID === getLinkedDocumentID(datTypeID, row);
            }),
        );
      } else {
        const newSelectedRows = [...prevSelectedRows];
        filteredRows?.forEach(row => {
          if (
            !newSelectedRows.some(selectedRow => selectedRow.linkedDocumentID === getLinkedDocumentID(datTypeID, row))
          ) {
            newSelectedRows.push({
              refNo: row.refNumber || '',
              invoiceTotal: row.totalInc,
              linkedDocumentID: getLinkedDocumentID(datTypeID, row),
            });
          }
        });
        return newSelectedRows;
      }
    });
  };

  const handleSearchInputChange = (value: string) => {
    setSearchValue(value);
  };

  const matchAndLink = async () => {
    try {
      setLoadingState('matchAndLinkLoading', true);
      const payload = {
        messageID: selectedStagedTransaction?.messageID,
        linkedDocumentIDs: selectedDocuments,
        adjustmentAmount: adjustmentAmount,
        isPostAsNew: false,
        isRoundingAdjustment:
          PSBLSTAGING && selectedPostingType === PostingTypeOptions.PostAsARoundingAdjustment ? true : undefined,
      };
      await matchAndLinkTransaction(payload);
      setLoadingState('matchAndLinkLoading', false);
    } catch (error) {
      console.error('Error matching and linking:', error);
    }
    handleDone?.();
  };

  const handleMatchAndLinkClick = () => {
    if (totalCapricornAmount !== undefined) {
      if (totalSelectedRecordsAmount !== totalCapricornAmount) {
        if (PSBLSTAGING) {
          setIsShowSelectPostingMethodDialog(true);
        } else {
          setIsShowAmountNotMatchDialog(true);
        }
      } else {
        matchAndLink();
      }
    }
  };

  const handlePostingType = (selectedPostingType: PostingTypeOptions) => {
    setSelectedPostingType(selectedPostingType);
  };

  const handleMatchAndLinkDone = () => {
    setIsShowAmountNotMatchDialog(false);
    matchAndLink();
  };

  const handleMatchAndLinkClose = () => {
    setIsShowSelectPostingMethodDialog(false);
    setSelectedRows([]);
    setPreviewDocumentId(undefined);
  };

  const handleWarningDialogDone = () => {
    setIsShowAmountNotMatchDialog(false);
    matchAndLink();
  };

  const handlWarningDialogClose = () => {
    setSelectedRows([]);
    setIsShowAmountNotMatchDialog(false);
    setPreviewDocumentId(undefined);
  };

  const columns = [
    {
      title: '',
      key: 'id',
      width: '',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      headerRenderer: () => {
        const isNotSelectable = filteredRows?.length === 0;
        const isSelected =
          !!filteredRows?.length &&
          filteredRows.every(row => {
            return selectedRows.some(
              selectedRow => selectedRow.linkedDocumentID === getLinkedDocumentID(datTypeID, row),
            );
          });
        return (
          <>
            {isNotSelectable ? (
              <ARTooltip
                arrow
                title={
                  <Typography
                    variant="paragraph"
                    className="min-w-[200px] text-center text-[10px] font-medium text-black-800"
                  >
                    Not selectable
                  </Typography>
                }
              >
                <span
                  className={`cursor-pointer items-center font-poppins text-base font-normal opacity-30 grayscale`}
                  data-autoid={`chkAllTransactionRow`}
                >
                  <ARSelectToggler
                    dataAutoId="chkAdapter"
                    containerClassName="!py-0"
                    key={`checkbox`}
                    isSelected={isSelected}
                  />
                </span>
              </ARTooltip>
            ) : (
              <span
                className={`cursor-pointer items-center font-poppins text-base font-normal`}
                data-autoid={`chkTransactionRow`}
                onClick={() => handleSelectAll()}
              >
                <ARSelectToggler
                  dataAutoId="chkAdapter"
                  containerClassName="!p-0"
                  key={`checkbox`}
                  isSelected={isSelected}
                />
              </span>
            )}
          </>
        );
      },
      rowRenderer: (rowData: IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue) => {
        const isSelected = selectedRows.some(
          selectedRow => selectedRow?.linkedDocumentID === getLinkedDocumentID(datTypeID, rowData),
        );
        return (
          <>
            <span
              className={`cursor-pointer items-center font-poppins text-base font-normal`}
              data-autoid={`chkTransactionRow`}
              onClick={() =>
                handleSelect({
                  invoiceTotal: rowData?.totalInc,
                  refNo: rowData?.refNumber,
                  linkedDocumentID: getLinkedDocumentID(datTypeID, rowData),
                })
              }
            >
              <ARSelectToggler
                containerClassName="!p-0"
                key={`checkbox${rowData?.refNumber}`}
                isSelected={isSelected}
              />
            </span>
          </>
        );
      },
    },
    {
      title: 'Ref no.',
      key: 'refNo',
      width: '25%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue) => (
        <Typography
          data-autoid={`lblRefNo`}
          variant="paragraph"
          className={clsx(
            `inline  overflow-ellipsis text-black-800`,
            rowData?.isPossibleMatch ? 'font-bold' : 'font-medium',
          )}
        >
          {rowData?.refNumber}
          {rowData?.isPossibleMatch && (
            <Typography variant="xsmall" className="font-medium text-black-800">
              (Possible Match)
            </Typography>
          )}
        </Typography>
      ),
    },
    {
      title: 'Date',
      key: 'date',
      width: '25%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue) => (
        <Typography
          data-autoid={`lblDate`}
          variant="paragraph"
          className={clsx(
            `inline  overflow-ellipsis text-black-800`,
            rowData?.isPossibleMatch ? 'font-bold' : 'font-medium',
          )}
        >
          {'invoiceDate' in rowData
            ? moment(rowData.invoiceDate).format('DD MMM YYYY')
            : moment(rowData.debitNoteDate).format('DD MMM YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Total',
      key: 'invoiceTotal',
      width: '25%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue) => (
        <Typography
          data-autoid={`lblInvoiceTotal`}
          variant="paragraph"
          className={clsx(
            `inline overflow-ellipsis text-black-800`,
            rowData?.isPossibleMatch ? 'font-bold' : 'font-medium',
          )}
        >
          {roundTo(rowData?.totalInc, 2, true)}
        </Typography>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '20%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue) => (
        <Typography
          data-autoid={`lblAction`}
          variant="paragraph"
          className={clsx(
            `inline cursor-pointer overflow-ellipsis text-black-800 text-primary`,
            rowData?.isPossibleMatch ? 'font-bold' : 'font-medium',
          )}
          onClick={() => {
            setSelectedRows([]);
            handleSelect({
              invoiceTotal: rowData?.totalInc,
              refNo: rowData?.refNumber,
              linkedDocumentID: getLinkedDocumentID(datTypeID, rowData),
            });
            const id =
              (rowData as IPurchaseInvoiceSummmaryValue)?.purchaseInvoiceID ||
              (rowData as IDebitNoteSummmaryValue)?.debitNoteID;
            setPreviewDocumentId(id);
          }}
        >
          Preview
        </Typography>
      ),
    },
  ];

  return (
    <div className="flex h-full max-h-[1080px] w-[638px] max-w-[1020px] flex-col rounded-[10px] bg-white shadow-md">
      {previewDocumentId && (
        <div
          className={`absolute my-2.5 ml-2.5 flex h-[45px] w-[45px] cursor-pointer flex-col items-center justify-center rounded-[6px] border-[1px] border-primary`}
          onClick={() => setPreviewDocumentId(undefined)}
          data-autoid={`btnARBackButton`}
        >
          <GoBackIcon />
        </div>
      )}
      <div className="header flex h-16 min-h-[60px] flex-col items-center justify-center border-b border-[#ccc]">
        {isPurchaseInvoice ? (
          <Typography variant="h2" className="text-black">
            Invoice/s from {financialAdaptorDisplayName}
          </Typography>
        ) : (
          <Typography variant="h2" className="text-black">
            Credit Note from {financialAdaptorDisplayName}
          </Typography>
        )}
      </div>
      <div
        className={clsx(
          'body relative flex flex-col items-center justify-center !px-2.5',
          isMatchedDocument ? 'h-full' : 'h-[calc(100%-60px)] ',
        )}
      >
        <div
          className={`relative flex h-full w-full flex-col items-center justify-start ${isMatchedDocument ? 'px-[54px]' : 'px-[30px]'} pb-20`}
        >
          {isMatchedDocument || previewDocumentId ? (
            <>
              <Typography variant="small" className="py-[30px] text-center text-black">
                Please review them before proceeding with 'Match & Link'.
              </Typography>
              <div className="flex h-[calc(100%-150px)] w-full flex-col overflow-y-auto">
                <InvoiceFromAdapterTemplate
                  datTypeId={datTypeID}
                  previewDocumentId={selectedStagedTransaction?.matchedDocumentID || previewDocumentId}
                />
              </div>
            </>
          ) : (
            <>
              <div className="my-[30px] w-[375px]">
                <SearchInput
                  value={searchValue}
                  onChange={e => handleSearchInputChange(e.target.value)}
                  clearIcon
                  clearIconProps={{
                    onClick: () => handleSearchInputChange(''),
                  }}
                  autoFocus
                  expanded
                  placeholder={'Search by ref number or amount'}
                  reverse={true}
                  data-autoid="txtSearchBar"
                  iconButtonProps={{'data-autoid': `btnSearch`}}
                />
              </div>
              <div
                data-autoid={`tblAdaptorInvoices_scroll`}
                className={`invoicesList flex h-[calc(100%-150px)] w-full flex-col overflow-y-auto bg-white`}
              >
                <ARTable
                  isPaginated
                  dataAutoId={`AdaptorInvoices`}
                  tableClass="!mb-0"
                  isLoading={isLoading?.dataLoading}
                  columns={columns}
                  rows={filteredRows || []}
                  getRowDataAutoId={(rowData: IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue) =>
                    `rowAdapterInvoices${rowData?.refNumber}`
                  }
                />
              </div>

              {isPurchaseInvoice ? (
                <Typography variant="h3" className="pt-[25px] text-center text-[#666666]">
                  ({selectedRows.length} invoice{selectedRows.length >= 1 && 's'} selected){' '}
                  {roundTo(totalSelectedRecordsAmount, 2, true)}
                </Typography>
              ) : (
                <Typography variant="h3" className="pt-[25px] text-center text-[#666666]">
                  ({selectedRows.length} credit note{selectedRows.length >= 1 && 's'} selected){' '}
                  {roundTo(totalSelectedRecordsAmount, 2, true)}
                </Typography>
              )}
            </>
          )}
          {isShowTooltipAlert && totalSelectedRecordsAmount !== totalCapricornAmount && PSBLSTAGING && (
            <div className="absolute bottom-20 right-0 w-[75%]">
              <div className="relative rounded-md border-primary-header bg-primary-background px-6 py-4 text-center text-xs font-normal text-black-800">
                <CloseCross
                  className="absolute right-2 top-2 !h-[8px] !w-[8px] cursor-pointer text-[#999999]"
                  onClick={() => setIsShowTooltipAlert(false)}
                />
                If you are matching two invoices with different amounts, we will give you some options about how that’s
                handled in your financial system on the following screen.{' '}
              </div>
            </div>
          )}
        </div>
        <div
          className={`absolute bottom-2.5 flex h-[60px] w-[97%] flex-row items-center justify-end rounded-[6px] bg-[#ececec] px-2.5`}
        >
          <Button
            data-autoid={`btnMatch&Link`}
            variant="filled"
            disabled={!selectedDocuments.length}
            color="primary"
            loading={isLoading?.matchAndLinkLoading}
            onClick={handleMatchAndLinkClick}
          >
            Match & Link
          </Button>
        </div>
      </div>
      {isShowSelectPostingMethodDialog && PSBLSTAGING && (
        <SelectMatchAndLinkMethod
          isOpen={isShowSelectPostingMethodDialog}
          onClose={handleMatchAndLinkClose}
          handleDone={handleMatchAndLinkDone}
          formattedAdjustmentAmount={formattedAdjustmentAmount}
          supplier={financialAdaptorDisplayName}
          handlePostingType={handlePostingType}
          selectedPostingType={selectedPostingType}
          isCOARoundeingSelected={Boolean(roundingConfig?.roundingAccountCode)}
          isLoading={isLoading?.matchAndLinkLoading}
        />
      )}
      {isShowAmountNotMatchDialog && totalCapricornAmount && (
        <WarningDialog
          conrfirmBtn={{
            title: 'Yes',
            isLoading: isLoading?.matchAndLinkLoading,
            onClick: handleWarningDialogDone,
          }}
          cancelBtn={{
            title: 'No',
            onClick: handlWarningDialogClose,
          }}
          title="Warning"
          message={
            <p>
              The total on the selected invoice does not match up. To resolve this quickly, we can automatically create
              an adjustment {totalSelectedRecordsAmount < totalCapricornAmount ? 'invoice' : 'credit note'} for{' '}
              <span data-autoid={`txtWarningMsg`}>{formattedAdjustmentAmount}</span> in your accounting system. Select
              ‘YES’ to create the adjustment or ‘NO’ to make changes yourself.
            </p>
          }
          onClose={() => setIsShowAmountNotMatchDialog(false)}
          isOpen={isShowAmountNotMatchDialog}
        />
      )}
    </div>
  );
};
