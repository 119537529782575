import React, {useContext, useState} from 'react';
import {Box} from '@material-ui/core';
import {Done, Edit} from '@material-ui/icons';
import {useFormikContext} from 'formik';
import {Badge, IconButton, Input, Typography} from 'spenda-ui-react';

import {AttachDocs} from '../../../assets/svg/AttachDocs';
import {
  useClaimsAndReturnHook,
  useClaimsAndReturnInputHook,
  usePSBLHook,
  useTransactionViewHook,
} from '../../../hooks/useARHook';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {useTenantInfo} from '../../../hooks/useTenantInfo';
import {
  ClaimReasons,
  ClaimsAndReturnsStatus,
  ConversationType,
  StartConvoType,
} from '../../../model/constants/Constants';
import {IClaimRequestFormValues} from './CreateAndEditClaimRequest';
import StartConvo from '../../../components/conversationChatBox/StartConvo';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import {IClaimRequestDetailsResponse} from '../../../model/claims/ClaimsAndReturns';
import EditEvidenceDialog from '../../../components/AccountsReceivable/EditEvidenceDialog';
import IconDownloadClaim from '../../../assets/svg/IconDownloadClaim';
import LoadingIndicator from '../../../components/ui/LoadingIndicator';
import ARStatusLabel from '../../../components/AccountsReceivable/ARStatusLabel';
import {SwitchSlider} from '../../../components/form/SwitchSlider';
import {ClaimsAndReturnActionReasonDropdown} from './ClaimsAndReturnInputs';
import {IconNoteBook} from '../../../assets/svg';
import ClaimsInternalNotesDialog from '../../../components/AccountsReceivable/ClaimsInternalNotesDialog';

interface IEditClaimHeader {
  claimRequestId?: string;
  claimRequestDetails?: IClaimRequestDetailsResponse;
  linkedSupplierId?: number;
  accountCustomerId?: number;
  handleCreateClaim: () => Promise<IClaimRequestDetailsResponse | undefined>;
  handleRefetchClaimDetails: (crId: number) => void;
  handleUpdateClaim: () => Promise<IClaimRequestDetailsResponse | undefined>;
}

export const CreateAndEditClaimRequestHeader = (props: IEditClaimHeader) => {
  // Props
  const {
    claimRequestId,
    claimRequestDetails,
    linkedSupplierId,
    accountCustomerId,
    handleCreateClaim,
    handleRefetchClaimDetails,
    handleUpdateClaim,
  } = props;

  // State
  const [iSShowUploadAttachmentModal, setISShowUploadAttachmentModal] = useState(false);
  const [isShowInternalNotesDialog, setIsShowInternalNotesDialog] = useState(false);

  // Context
  const {conversations} = useContext(PurchasingContext);

  // Hooks
  const {getSupplierWhoRaisedInvoice} = useTransactionViewHook();
  const {isInBuyerContext, isInSupplierContext} = useTenantInfo();
  const {getSelectedSupplier} = usePSBLHook();
  const {reasonDropdownOptions} = useClaimsAndReturnInputHook({linkedSupplierID: linkedSupplierId});
  const {downloadClaimDocument, isDownloadClaimLoading} = useClaimsAndReturnHook();
  const formikProps = useFormikContext<IClaimRequestFormValues>();

  // Feature Flags
  const {claimAndReturnsV272602: claimAndReturnsV2Supplier} = useFeatureFlags().tenantOwned();
  const {claimAndReturnsV272602: claimAndReturnsV2Buyer} = useFeatureFlags().supplierDriven();
  const claimAndReturnsV272602 = isInBuyerContext
    ? claimAndReturnsV2Buyer && claimAndReturnsV2Supplier
    : claimAndReturnsV2Supplier;

  // States
  const [toggleInput, setToggleInput] = useState({
    defaultReason: false,
    defaultAction: false,
    comment: false,
  });

  // Constants
  const SupplierWhoRaisedInvoice = getSupplierWhoRaisedInvoice();
  const currentSupplier = getSelectedSupplier(linkedSupplierId);
  const attachmentCount =
    claimRequestDetails?.lines?.reduce((a, c) => a + (c?.claimRequestAttachments?.length || 0), 0) || 0;

  const handleDownloadClaimDocument = async () => {
    try {
      if (!claimRequestId && (!linkedSupplierId || !accountCustomerId)) return;
      await downloadClaimDocument({claimRequestId: Number(claimRequestId), linkedSupplierId, accountCustomerId});
    } catch {}
  };

  const handleNotesClick = async () => {
    let claimRequestDetailResponse;
    if (claimRequestDetails?.claimRequestID) {
      // update claim
      claimRequestDetailResponse = await handleUpdateClaim();
      if (claimRequestDetailResponse) {
        handleRefetchClaimDetails(claimRequestDetailResponse.claimRequestID);
      }
      setIsShowInternalNotesDialog(true);
    } else {
      // create claim
      claimRequestDetailResponse = await handleCreateClaim();
      if (claimRequestDetailResponse) {
        await handleRefetchClaimDetails(claimRequestDetailResponse.claimRequestID);
        setIsShowInternalNotesDialog(true);
      }
    }
  };

  const handleClaimsNoteClose = async () => {
    setIsShowInternalNotesDialog(false);
    handleRefetchClaimDetails(claimRequestDetails?.claimRequestID!);
  };

  return (
    <>
      <div className="relative">
        <div className=" h-full w-full rounded-[6px] bg-white shadow-[0px_0px_6px_#D3E5EF]">
          <div className="border-b-solid flex flex-row justify-between border-b-[1px] border-b-[#D8D8D8] px-5 py-[14px]">
            <div className="flex flex-row items-center gap-2">
              <Typography className="font-light text-black-800" variant="h2">
                {!claimRequestId ? 'Create Claim' : `Claim ${formikProps?.values?.claimCode || ''}`}
              </Typography>
              {claimAndReturnsV272602 && isInSupplierContext && false && (
                <Typography
                  data-autoid={`lblDisputedClaim`}
                  className={`inline rounded-lg bg-[#F4E8D1]`}
                  variant="xsmall"
                >
                  <ARStatusLabel color="#C68A19" bg="#C68A191">
                    Disputed Claim
                  </ARStatusLabel>
                </Typography>
              )}

              {claimAndReturnsV272602 &&
                claimRequestId &&
                (isDownloadClaimLoading ? (
                  <div className="relative pr-5">
                    <LoadingIndicator size="sm" isLoading={isDownloadClaimLoading} />
                  </div>
                ) : (
                  <button
                    onClick={handleDownloadClaimDocument}
                    type="button"
                    data-autoid="btnDownloadClaim"
                    className={
                      'relative flex h-10 w-10 items-center justify-center rounded-[6px] border-default focus:outline-none'
                    }
                  >
                    <IconDownloadClaim />
                  </button>
                ))}
            </div>
            {claimAndReturnsV272602 && (
              <div className="flex flex-row justify-end gap-2.5">
                {/* {isInSupplierContext && (
                  <div
                    data-autoid="btnCreateAndEditClaimRequestHeaderChildClaim"
                    className="flex flex-row justify-center items-center h-[40px] gap-2 rounded-[6px] px-2 font-poppins text-base border-[1px] border-[#1c78ad] cursor-pointer z-10"
                  >
                    <ChildClaimIcon />
                    <p>Child claim</p>
                  </div>
                )} */}
                {isInSupplierContext && claimAndReturnsV272602 && claimRequestId && (
                  <span
                    data-autoid={`btnPendingCustomerActionButton`}
                    className={`flex h-[45px] w-[296px] items-center justify-between rounded-[6px]  bg-[#F6F6F6] pl-3 font-poppins text-lg font-medium text-black-800`}
                  >
                    Pending Customer Action
                    <Box className="pl-3 text-right">
                      <SwitchSlider
                        disabled={
                          formikProps?.values?.status !== ClaimsAndReturnsStatus?.Sent &&
                          formikProps?.values?.status !== ClaimsAndReturnsStatus?.Reviewed
                        }
                        className="h-[31px] w-[51px]"
                        checked={formikProps?.values?.isPendingCustomerAction || false}
                        handleChange={e => {
                          formikProps?.setFieldValue(`isPendingCustomerAction`, e.target.checked);
                        }}
                        name="isPendingCustomerAction"
                      ></SwitchSlider>
                    </Box>
                  </span>
                )}

                {claimAndReturnsV272602 && (
                  <IconButton variant="outlined" data-autoid="btnAddInternalNotes" onClick={handleNotesClick}>
                    <IconNoteBook />
                  </IconButton>
                )}

                {claimRequestId && claimRequestDetails && (
                  <StartConvo
                    scope={'CR_CreateAndEdit'}
                    operationUniqueId={claimRequestDetails?.claimGUID}
                    conversationWith={
                      isInBuyerContext
                        ? currentSupplier?.SupplierName || ''
                        : claimRequestDetails?.accountCustomerName || ''
                    }
                    refNumber={claimRequestDetails?.refNumber}
                    supplierID={isInBuyerContext ? linkedSupplierId : null}
                    customerID={isInSupplierContext ? claimRequestDetails?.accountCustomerID : null}
                    datTypeID={claimRequestDetails?.datTypeID}
                    guid={claimRequestDetails?.claimGUID}
                    contextConvos={conversations}
                    startConvoType={StartConvoType.ICON}
                    convoType={ConversationType.DOCUMENT}
                    unreadMessages={claimRequestDetails?.unreadMessages}
                    conversationId={claimRequestDetails?.conversationID}
                  />
                )}
                {(claimRequestDetails || claimRequestId) && (
                  <Badge content={attachmentCount} className="h-[24px] w-[22px] font-poppins" color="error">
                    <IconButton
                      variant="outlined"
                      color="primary"
                      data-autoid="btnAttachDocs"
                      onClick={() => setISShowUploadAttachmentModal(true)}
                    >
                      <AttachDocs className="-rotate-45 cursor-pointer" width="22px" height="auto" />
                    </IconButton>
                  </Badge>
                )}
              </div>
            )}
          </div>
          <div className="mx-5 mt-6 flex flex-wrap items-start justify-start pb-3">
            <table>
              <tbody>
                <tr>
                  <td className=" !pr-5 align-top">
                    <Typography className=" font-normal text-black-800" variant="paragraph">
                      Supplier name:
                    </Typography>
                  </td>
                  <td className="align-top">
                    <div className=" !w-[200px]">
                      <Typography className="font-semibold text-[#444343]" data-autoid={`lblName`} variant="paragraph">
                        {SupplierWhoRaisedInvoice?.Name}
                      </Typography>
                      <Typography
                        className=" font-normal text-spenda-greytext"
                        data-autoid={`lblBusinessPhoneVendorName`}
                        variant="xsmall"
                      >
                        {SupplierWhoRaisedInvoice?.BusinessPhone}{' '}
                        {formikProps?.values?.vendorName && `(${formikProps?.values?.vendorName})`}
                      </Typography>
                    </div>
                  </td>
                  <td className=" !pr-5 align-top">
                    <Typography className="font-normal  text-black-800" variant="paragraph">
                      Invoice no:
                    </Typography>
                    {formikProps?.values?.vendorRefNumber && (
                      <Typography className="font-normal text-[#444343]" variant="xsmall">
                        Vendor no:
                      </Typography>
                    )}
                  </td>
                  <td className="align-top">
                    <div className=" !w-[200px]">
                      <Typography
                        className="font-semibold text-[#444343]"
                        variant="paragraph"
                        data-autoid={`lblInvoiceRefNo`}
                      >
                        {formikProps?.values?.invoiceRefNumber}
                      </Typography>
                      <Typography
                        className="font-normal text-[#444343]"
                        data-autoid={`lblVendorRefNo`}
                        variant="xsmall"
                      >
                        {formikProps?.values?.vendorRefNumber}
                      </Typography>
                    </div>
                  </td>
                  <td className=" h-[40px] !pr-5 align-top">
                    <Typography className="font-normal text-black-800" variant="paragraph">
                      Default reason:
                    </Typography>
                  </td>
                  <td className="h-[40px] !pr-5 align-top">
                    <div className=" !w-[200px]">
                      {!toggleInput?.defaultReason ? (
                        <Typography className="font-semibold text-black-800" variant="paragraph">
                          {claimAndReturnsV272602
                            ? formikProps?.values?.defaultReason
                            : Object.entries(ClaimReasons)
                                ?.find(([_key, value]) => value === formikProps?.values?.defaultReason)?.[0]
                                ?.replace(/([A-Z])/g, ' $1')
                                .trim()}
                          <Edit
                            data-autoid={`btnEditDefaultReason`}
                            className=" !h-[14px] cursor-pointer text-primary"
                            onClick={() => setToggleInput({...toggleInput, defaultReason: true})}
                          />
                        </Typography>
                      ) : (
                        <ClaimsAndReturnActionReasonDropdown
                          label={'Default reason'}
                          className="!h-[45px] !min-w-[170px]"
                          dataAutoId={`ddlSelectBatchPeriodCustomerFocused`}
                          value={formikProps?.values?.defaultReason}
                          onChange={e => {
                            formikProps?.handleChange(e);
                            setToggleInput({...toggleInput, defaultReason: false});
                          }}
                          onBlur={formikProps?.handleBlur}
                          name="defaultReason"
                          reasonDropdownOptions={reasonDropdownOptions}
                          showSubReason={false}
                          claimAndReturnsV272602={claimAndReturnsV272602}
                          type="reason"
                        />
                      )}
                    </div>
                  </td>
                  <td className=" h-[40px] !pr-5 align-top">
                    <Typography className="font-normal text-black-800" variant="paragraph">
                      Default action:
                    </Typography>
                  </td>
                  <td className="h-[40px] align-top">
                    <div className=" !w-[200px]">
                      {!toggleInput?.defaultAction ? (
                        <Typography className="font-semibold text-black-800" variant="paragraph">
                          {formikProps?.values?.defaultAction}
                          <Edit
                            data-autoid={`ddlEditDefaultAction`}
                            className=" !h-[14px] cursor-pointer text-primary"
                            onClick={() => setToggleInput({...toggleInput, defaultAction: true})}
                          />
                        </Typography>
                      ) : (
                        <ClaimsAndReturnActionReasonDropdown
                          label="Default action"
                          className="!h-[45px] !min-w-[170px]"
                          variant="outlined"
                          dataAutoId={`ddlSelectBatchPeriodCustomerFocused`}
                          value={formikProps?.values?.defaultAction}
                          onChange={e => {
                            formikProps?.handleChange(e);
                            setToggleInput({...toggleInput, defaultAction: false});
                          }}
                          onBlur={formikProps?.handleBlur}
                          name="defaultAction"
                          type="action"
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="h-[40px] !pr-5 !pt-10 pb-6 align-top">
                    <Typography className="font-normal text-black-800" variant="paragraph">
                      Comment:
                    </Typography>
                  </td>
                  <td className="h-[40px] !pt-10 align-top" colSpan={3}>
                    <div className="h-[56px]">
                      {!toggleInput?.comment ? (
                        <Typography className="font-semibold text-[#444343]" variant="paragraph">
                          {formikProps?.values?.comment}
                          {formikProps?.values?.status === ClaimsAndReturnsStatus.Draft && (
                            <Edit
                              data-autoid={`ddlEditComment`}
                              className=" !h-[14px] cursor-pointer text-primary"
                              onClick={() => setToggleInput({...toggleInput, comment: true})}
                            />
                          )}
                        </Typography>
                      ) : (
                        <div className="font-poppins font-normal">
                          <Input
                            disabled={formikProps?.values?.status !== ClaimsAndReturnsStatus.Draft}
                            maxLength={250}
                            id="Comment"
                            name="comment"
                            value={formikProps?.values?.comment || ''}
                            onChange={formikProps?.handleChange}
                            onBlur={formikProps?.handleBlur}
                            type="text"
                            label="Comment"
                            data-autoid={`txtComment`}
                            className="font-poppins text-[10px]"
                            InputProp={{
                              endAdornment: (
                                <button
                                  className="!px-0"
                                  data-autoid="btnclearFriendlyName"
                                  onClick={() => setToggleInput({...toggleInput, comment: false})}
                                >
                                  <Done className="text-black-800" />
                                </button>
                              ),
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {iSShowUploadAttachmentModal && (
          <EditEvidenceDialog
            isViewOnly
            showModal={iSShowUploadAttachmentModal}
            handleClose={() => setISShowUploadAttachmentModal(false)}
            claimRequestDetails={claimRequestDetails}
            linkedSupplierId={linkedSupplierId}
            accountCustomerId={accountCustomerId}
          />
        )}
        {isShowInternalNotesDialog && (
          <ClaimsInternalNotesDialog
            handleClose={handleClaimsNoteClose}
            claimRequestID={claimRequestDetails?.claimRequestID!}
            linkedSupplierId={linkedSupplierId}
            accountCustomerId={accountCustomerId}
          />
        )}
      </div>
    </>
  );
};
