import React, {useContext, useEffect, useState} from 'react';
import {FormikProps} from 'formik';
import {Divider} from '@material-ui/core';
import AppContext from '../../context/app/appContext';
import useMarketPlaceAPI from '../../services/useMarketPlaceAPI';
import {LogoTypeEnum} from '../../model/Tenant';
import DeleteIcon from '@material-ui/icons/Delete';
import {BusinessDetailsFormValues} from '../onboarding/BusinessDetails';
import copyIcon from '../../assets/svg/copyIcon.svg';
import {Input, Button, Typography, Card, IconButton, Upload} from 'spenda-ui-react';
import {AlertDialog} from '../dialog/AlertDialogSlideV2';

export type CompanyDetailsFormProps = {
  isShowDiscardChangesConfirmation?: boolean;
  setIsShowDiscardChangesConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
};

interface ShowAlertDialogType {
  logo: boolean;
  icon: boolean;
  banner: boolean;
}

export const CompanyDetailsForm = (props: FormikProps<BusinessDetailsFormValues> & CompanyDetailsFormProps) => {
  // State
  const [isMarketPlacePathCreated, setIsMarketPlacePathCreated] = useState<boolean>(false);
  const [showAlertDialog, setShowAlertDialog] = useState<ShowAlertDialogType>({
    logo: false,
    icon: false,
    banner: false,
  });

  // Context
  const {user, tenant} = useContext(AppContext);
  const companyName = user?.TenantName?.replace(/[\W_]/g, '') || undefined; //regex to remove special chars & white spaces

  const suggestedPath = (url: string) => {
    return !!url
      ? url
          .toLowerCase()
          .replace(/[^a-zA-Z0-9\s]/g, '')
          .replace(/\s+/g, '-')
      : '';
  };

  useEffect(() => {
    if (tenant) {
      let newValues = {
        companyName: tenant.Name ?? '',
        abn: tenant.ABN ?? '',
        companyPhone: tenant.Phone ?? '',
        website: tenant.WebSiteURL ?? '',
        mySpendaUrl: tenant.MarketPlacePath ?? suggestedPath(companyName ?? ''),
        arContactEmailAddress: tenant.ARContactEmailAddress,
        companyEmailAddress: tenant.EmailAddress ?? '',
        companyTagLine: tenant.TagLine ?? '',
        // These will be setup below
        companyLogo: '',
        companyLogoThumb: '',
        companyIcon: '',
        companyIconThumb: '',
        companyBanner: '',
        companyBannerThumb: '',
      } as BusinessDetailsFormValues;

      if (tenant.Logos?.length) {
        for (let logo of tenant.Logos) {
          if (logo.LogoTypeID == LogoTypeEnum.Banner) {
            newValues.companyBanner = logo.URI;
            newValues.companyBannerThumb = logo.ThumbnailURL;
          } else if (logo.LogoTypeID == LogoTypeEnum.Logo) {
            newValues.companyLogo = logo.URI;
            newValues.companyLogoThumb = logo.ThumbnailURL;
          } else if (logo.LogoTypeID == LogoTypeEnum.Icon) {
            newValues.companyIcon = logo.URI;
            newValues.companyIconThumb = logo.ThumbnailURL;
          }
        }
      }
      props.resetForm({
        values: newValues,
      });
      // setIsMarketPlacePathCreated(t.MarketPlacePath ? true : false);
      setIsMarketPlacePathCreated(!!tenant.MarketPlacePath);
    }
  }, []);

  const marketplaceAPI = useMarketPlaceAPI();

  const domain = window.location.origin;
  const mySpendaWithMarketplace = domain + '/' + props.values.mySpendaUrl;

  const checkPathUnique = async () => {
    marketplaceAPI.marketPlacePathUnique(props.values.mySpendaUrl).then((isUnique: boolean) => {
      if (isUnique) {
        setIsMarketPlacePathCreated(true);
      }
    });
  };

  const filesChanged = (files: FileList | null, fieldName: string) => {
    if (!files?.length) {
      return;
    }
    const file: File = files[0];

    if (!file.name) {
      return;
    }

    const reader = new FileReader();

    reader.onloadend = () => {
      // 'reader.result' contains the data URL for the file
      const imgUrl = reader.result;
      props.setFieldValue(fieldName, imgUrl ?? '');
      props.setFieldValue(fieldName + 'Thumb', imgUrl ?? '');
    };

    reader.readAsDataURL(file);
    props.setFieldValue(`uploadedFiles.${fieldName}`, file);
  };

  const onUploadChange = (files: FileList, fieldName: string) => {
    filesChanged(files, fieldName);
  };

  const change = (
    name:
      | 'companyName'
      | 'abn'
      | 'companyPhone'
      | 'website'
      | 'arContactEmailAddress'
      | 'mySpendaUrl'
      | 'companyLogo'
      | 'companyIcon'
      | 'companyBanner'
      | 'companyLogoThumb'
      | 'companyIconThumb'
      | 'companyBannerThumb',
    e: {persist: () => void},
  ) => {
    e.persist();
    props.handleChange(e);
    props.setFieldTouched(name, true, false);
  };

  let tenantLogo = props.values.companyLogo ? [props.values.companyLogo] : [];
  let tenantIcon = props.values.companyIcon ? [props.values.companyIcon] : [];
  let tenantBanner = props.values.companyBanner ? [props.values.companyBanner] : [];

  const onRemoveAttachment = (fieldName: string) => {
    if (!props.getFieldMeta(fieldName).value) return;

    props.setFieldValue(fieldName, '');
    props.setFieldValue(`${fieldName}Thumb`, '');

    props.setFieldValue(`uploadedFiles.${fieldName}`, undefined);

    let stateKey = fieldName.replace('company', '').toLowerCase() as keyof ShowAlertDialogType;
    if (showAlertDialog[stateKey]) {
      setShowAlertDialog({...showAlertDialog, [stateKey]: false});
    }
  };

  const UploadedImageShow = (props: {image: string; fieldName: String; onClick?: () => void; imageClass?: string}) => {
    return (
      <div className="upload-img-field flex !h-full w-full items-center justify-center">
        <img src={props.image} alt="logo" className={props.imageClass}></img>
        <div className="overlay-img">
          <IconButton
            variant="text"
            className="ml-5"
            onClick={props.onClick}
            data-autoid={`btnDelete${props.fieldName}`}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    );
  };

  return (
    <form id="company-details-form" onSubmit={props.handleSubmit} className={`public-form px-2 pt-5`}>
      <div className="mb-7 flex">
        <div className="mr-2 w-1/2">
          <Input
            id="companyName"
            name="companyName"
            label="Business Name"
            helperText={props.touched.companyName ? props.errors.companyName : ''}
            error={props.touched.companyName && Boolean(props.errors.companyName)}
            value={props.values.companyName}
            autoComplete="off"
            onChange={change.bind(null, 'companyName')}
            data-autoid="txtBusinessName"
          />
        </div>
        <div className="ml-2 w-1/2">
          <Input
            label="ABN"
            id="abn"
            name="abn"
            helperText={props.touched.abn ? props.errors.abn : ''}
            error={props.touched.abn && Boolean(props.errors.abn)}
            value={props.values.abn}
            autoComplete="off"
            onChange={change.bind(null, 'abn')}
            data-autoid="txtAbn"
          />
        </div>
      </div>
      <div className="mb-7 flex">
        <div className="mr-2 w-1/2">
          <Input
            id="companyPhone"
            label="Business Phone"
            name="companyPhone"
            type="number"
            helperText={props.touched.companyPhone ? props.errors.companyPhone : ''}
            error={props.touched.companyPhone && Boolean(props.errors.companyPhone)}
            value={props.values.companyPhone}
            onChange={change.bind(null, 'companyPhone')}
            data-autoid="txtCompanyPhone"
          />
        </div>
        <div className="ml-2 w-1/2">
          <Input
            id="website"
            label="Website"
            name="website"
            error={props.touched.website && Boolean(props.errors.website)}
            helperText={props.touched.website ? props.errors.website : ''}
            value={props.values.website}
            onChange={change.bind(null, 'website')}
            data-autoid="txtWebsite"
          />
        </div>
      </div>

      <div className="mb-7 flex">
        <div className="mr-2 w-1/2">
          <Input
            id="companyEmailAddress"
            label="Business Email"
            name="companyEmailAddress"
            helperText={props.touched.companyEmailAddress ? props.errors.companyEmailAddress : ''}
            error={props.touched.companyEmailAddress && Boolean(props.errors.companyEmailAddress)}
            value={props.values.companyEmailAddress}
            onChange={change.bind(null, 'companyEmailAddress')}
            data-autoid="txtCompanyEmailAddress"
          />
        </div>
        <div className="ml-2 w-1/2">
          <Input
            id="companyTagLine"
            label="Business Tagline"
            name="companyTagLine"
            error={props.touched.companyTagLine && Boolean(props.errors.companyTagLine)}
            helperText={props.touched.companyTagLine ? props.errors.companyTagLine : ''}
            value={props.values.companyTagLine}
            onChange={change.bind(null, 'companyTagLine')}
            data-autoid="txtCompanyTagLine"
          />
        </div>
      </div>
      <div className="flex">
        <div className="mb-7 w-full">
          <Input
            id="arContactEmailAddress"
            name="arContactEmailAddress"
            error={props.touched.arContactEmailAddress && Boolean(props.errors.arContactEmailAddress)}
            label="These details will be added to the Accounts Receivable Invoice"
            value={props.values.arContactEmailAddress}
            onChange={change.bind(null, 'arContactEmailAddress')}
            data-autoid="txtArContactEmailAddress"
          />
        </div>
      </div>
      {/*************************************MARKETPLACE-URL*************************************/}
      {!isMarketPlacePathCreated && (
        <Divider
          style={{
            height: '2px !important',
            backgroundColor: '#ccc',
            marginBottom: '20px',
            marginTop: '0px',
          }}
        />
      )}
      {!isMarketPlacePathCreated && (
        <div className="mb-7 flex">
          <div className="relative flex w-full">
            <Input
              id="mySpendaUrl"
              error={props.touched.mySpendaUrl && Boolean(props.errors.mySpendaUrl)}
              onChange={change.bind(null, 'mySpendaUrl')}
              helperText={props.errors.mySpendaUrl}
              value={props.values.mySpendaUrl}
              name="mySpendaUrl"
              data-autoid="txtMySpendaUrl"
              label="Provide this link to your customers so they can connect to your Spenda Account."
              adornmentContainerClass="!pr-0"
              InputProp={{
                startAdornment: <Typography className="!line-clamp-1 w-max select-none">{domain + '/'}</Typography>,
                endAdornment: (
                  <Button
                    disabled={!!props.errors.mySpendaUrl || !props.values.mySpendaUrl || marketplaceAPI.isLoading}
                    className={`mr-[1px] whitespace-nowrap`}
                    loading={marketplaceAPI.isLoading}
                    data-autoid="btnCheckAvailability"
                    onClick={() => {
                      if (!props.errors.mySpendaUrl) checkPathUnique();
                    }}
                  >
                    Check Availability
                  </Button>
                ),
              }}
            />
          </div>
        </div>
      )}
      {/* has marketplace path - readonly */}

      {isMarketPlacePathCreated && (
        <div>
          <div className="mb-7">
            <Input
              id="mySpendaWithMarketplacePath"
              error={props.touched.mySpendaWithMarketplacePath && Boolean(props.errors.mySpendaWithMarketplacePath)}
              value={mySpendaWithMarketplace}
              name="mySpendaWithMarketplacePath"
              label="Provide this link to your customers so they can connect to your Spenda Account."
              disabled={!!mySpendaWithMarketplace}
              data-autoid="txtMySpendaWithMarketplacePath"
              InputProp={{
                endAdornment: (
                  <img
                    title="Copy"
                    className="cursor-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(mySpendaWithMarketplace);
                    }}
                    alt="iconCopy"
                    src={copyIcon}
                  />
                ),
              }}
            />
          </div>
        </div>
      )}
      {/*************************************MARKETPLACE-URL*************************************/}
      <Divider
        style={{
          height: '2px !important',
          backgroundColor: '#ccc',
          marginBottom: '20px',
          marginTop: '0px',
        }}
      />
      <div className="flex">
        <div className="mr-2 w-1/2">
          <div className="dropBox flex-col">
            <Typography className="mb-1">Business Logo</Typography>{' '}
            {tenantLogo.length ? (
              <Card className={`h-[122px] rounded-[10px] border border-primary bg-[#e3ecf1]`}>
                <UploadedImageShow
                  image={tenantLogo[0]}
                  fieldName="Logo"
                  onClick={() => setShowAlertDialog({...showAlertDialog, logo: true})}
                  imageClass="!w-auto !h-[100px]"
                />
              </Card>
            ) : (
              <Upload
                label="Drag and drop a file here or click"
                className="h-[122px]"
                onChange={(files: FileList) => onUploadChange(files, 'companyLogo')}
                data-autoid="txtUploadLogo"
              />
            )}
            <Typography variant="xsmall" className="my-2 text-[#9e9e9e]">
              This logo is used on your invoices and on your Spenda landing page.
            </Typography>
          </div>
        </div>
        <div className="ml-2 w-1/2">
          <div className="flex-col">
            <Typography className="mb-1">Business Icon</Typography>
            {tenantIcon.length ? (
              <Card className={`h-[122px] rounded-lg border bg-[#e3ecf1]`}>
                <UploadedImageShow
                  image={tenantIcon[0]}
                  fieldName="Icon"
                  onClick={() => setShowAlertDialog({...showAlertDialog, icon: true})}
                  imageClass="!w-[100px] !h-[100px]"
                />
              </Card>
            ) : (
              <Upload
                className="h-[122px]"
                label="Drag and drop a file here or click"
                onChange={(files: FileList) => onUploadChange(files, 'companyIcon')}
                data-autoid="txtUploadIcon"
              />
            )}
            <Typography variant="xsmall" className="mt-2 text-[#9e9e9e]">
              This Icon must be 100 x 100 px and is used for your customers in the My Spenda menu.
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex-col">
        <Typography className="mb-1">Business Banner</Typography>
        {tenantBanner.length ? (
          <Card className={`h-[142px] rounded-[10px] border border-primary bg-[#e3ecf1]`}>
            <UploadedImageShow
              image={tenantBanner[0]}
              fieldName="Banner"
              onClick={() => setShowAlertDialog({...showAlertDialog, banner: true})}
              imageClass="!w-auto !max-h-[120px]"
            />
          </Card>
        ) : (
          <Upload
            className="h-[122px]"
            label="Drag and drop a file here or click"
            onChange={(files: FileList) => onUploadChange(files, 'companyBanner')}
            data-autoid="txtUploadBanner"
          />
        )}

        <Typography variant="xsmall" className="mt-2 text-[#9e9e9e]">
          This banner must be 936 x 120 px and is used in the Spenda header and is viewable by any of your connected
          customers.
        </Typography>
      </div>
      {/* <Divider style={{ height: "2px !important", backgroundColor: "#ccc", marginTop: "40px" }} /> */}

      {props.isShowDiscardChangesConfirmation && (
        <AlertDialog
          id="discardPopup"
          title="Discard Changes"
          size="sm"
          actions={[
            {
              label: 'No',
              action: () => props.setIsShowDiscardChangesConfirmation(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: props.onClose,
            },
          ]}
          content="Are you sure you want to discard your changes ?"
        />
      )}
      {showAlertDialog.logo && (
        <AlertDialog
          title="Remove Logo"
          size="xs"
          actions={[
            {
              label: 'No',
              action: () => setShowAlertDialog({...showAlertDialog, logo: false}),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => onRemoveAttachment('companyLogo'),
            },
          ]}
          content="Would you like to delete this logo ?"
        />
      )}
      {showAlertDialog.banner && (
        <AlertDialog
          id="discardPopup"
          title="Remove Banner"
          size="xs"
          actions={[
            {
              label: 'No',
              action: () => setShowAlertDialog({...showAlertDialog, banner: false}),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => onRemoveAttachment('companyBanner'),
            },
          ]}
          content="Would you like to delete this banner ?"
        />
      )}
      {showAlertDialog.icon && (
        <AlertDialog
          id="discardPopup"
          title="Remove Icon"
          size="xs"
          actions={[
            {
              label: 'No',
              action: () => setShowAlertDialog({...showAlertDialog, icon: false}),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => onRemoveAttachment('companyIcon'),
            },
          ]}
          content="Would you like to delete this icon ?"
        />
      )}
    </form>
  );
};
